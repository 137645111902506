@import './boostrap-overrides.scss';
@import './layout.scss';
@import './helper.scss';
@import './nav.scss';

html {
    font-size: .875rem;
}

body, html {
    margin: 0;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
}

#root {
    height: 100vh;
}

a {color: var(--link-color);}
a:hover {color: var(--link-color)}

:root {
    --border-color: #e4e4e4;
    --gray-800: #27272A;
    --link-color: #cd3e17;
}
  