@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";

$theme-colors: (
  "success": #4FBC5B
);

//card 
.card {
    border: none;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important;
    table {
        margin-bottom: 0;
    }
}

.card-footer {
    text-align: right;
}

.card-title {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0 !important;
}

.card-header {
    background-color: #fff !important;
    border-bottom: none;
}

// button
.btn {
    min-width: 80px;
    text-transform: uppercase;
    padding: .25rem .75rem;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.26)
}

.btn.btn-link {
    padding: 0;
    min-width: 0;
    box-shadow: none;
    color: #cd3e17;
}

table {
    background-color: #fff;
    border: none;
    border-radius: .25rem;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    thead th {
        color: var(--gray-800);
        border-bottom: 1px solid var(--border-color) !important;
        text-transform: uppercase;
        font-weight: normal;
    }
    
    tbody td {
        border-bottom: 1px solid var(--border-color);
    }

    th, td {
        white-space: nowrap;
    }
}

.modal-dialog-scrollable form {
    display: flex;
    flex-direction: column;
    height: 100%;
}

@import "bootstrap";