.admin-layout {
    display: grid;

    grid-template-areas:
        "header header"
        "nav content";

    grid-template-columns: 200px 1fr;
    grid-template-rows: 60px 1fr;

    height: 100vh;
}

.admin-layout header {
    grid-area: header;
}

.admin-layout nav {
    grid-area: nav;
    
    .nav-link {
        width: 100%;
        color: #e9ecef;
    }
    .nav-link.active {
        color: #0EA5E9;
        font-weight: bold;
    }
}

.admin-layout main {
    grid-area: content;
    background-color: #E5E5E5;
    overflow-x: hidden;
}