.side-nav .active {
    color: #000;
    background-color: #cd3e17;
}

.nav-link {
    color: #E2E2E2 !important;
    padding: 1rem 1rem;
    text-transform: uppercase;
    font-weight: normal !important;
}

.nav-link.active {
    background-color: lighten($color: #262626, $amount: 10) !important;
    color: #4ebc5b !important;
}
.nav-link:hover {
    background-color: lighten($color: #262626, $amount: 10) !important;
}
.nav-link.active > img {
    filter: brightness(100);
}

.habitnu-nav {
    padding: 8px;
    border-bottom: 1px solid var(--border-color);
    a {
        padding: 8px;
        color: #fff;    
    }
   
    a:hover {
        color: #fff;
    }

    a.active {
        background-color: #cd3e17;
    }
}

.nav-profile {
    width: 200px;
    a {
        color: #000;
    }

    a.active {
        background-color: #777;
        color: #fff;
    }
}