.cursor-pointer {cursor: pointer;}

.overflow-auto {overflow: auto}
.required {color: red;font-weight: bold;}

.list-none {list-style-type: none;}

.text-multiline { white-space: pre-wrap; }

/* text transform */
.uppercase {text-transform: uppercase;}
.lowercase {text-transform: lowercase;}
.capitalize	{text-transform: capitalize;}
.normal-case {text-transform: none;}
.text-transform-inherit { text-transform: inherit; }

/* font size */
.text-sm {font-size: 0.875rem;line-height: 1.25rem;}
.text-base {font-size: 1rem; line-height: 1.5rem;}
.text-lg {font-size: 1.125rem; line-height: 1.75rem;}
.text-xl {font-size: 1.25rem; line-height: 1.75rem;}
.text-2xl {font-size: 1.5rem; line-height: 2rem;}

/*display*/
.display-block {display: block;}